<template>
   <div class="player-video_component">
      <div class="container" v-if="loading">
         <div class="player__video video_play">
            <p class="player__video_yers" v-if="video_info.censored != 99">{{ video_info.censored }}+</p>
            <video ref="videoPlayer" class="video-js vjs-custom-skin">
            </video>
         </div>
      </div>
      <div class="container" v-else>
         <div class="center-loading">
            <div class="lds-ring">
               <div></div>
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>
   </div>

   <div class="setting__quality" ref="list" v-if="video_info.video_kach">
      <span @click="showMenuList()"><img src="@/assets/img/settings.svg"></span>
      <ul v-if="menu" class="menu_quality-video" :class="{ 'one': video_info.video_kach.length == 1 }">
         <li v-for="list in video_info.video_kach"
            :class="{ 'active': kach_current == list.kach, 'one': video_info.video_kach.length == 1 }"
            @click="SetQuality(list.kach, list.link_video)">
            <a>{{ list.kach }}</a>
         </li>
      </ul>
   </div>
</template>
<style lang="scss">
@import "@/assets/scss/video.scss";
</style>
<style>
@import '@/assets/css/VideoComponent/main.css';
</style>
<script>
import axios from 'axios'
import moment from 'moment';
import videojs from 'video.js';


export default {
   name: 'VideoComponent',
   emits: ['updateLink'],
   data() {
      return {
         player: null,
         loading: true,
         menu: false,
         kach_current: 720,
      }
   },
   components: {

   },
   props: {
      options: {
         type: Object,
         default: {}
      },
      detail: {
         type: Object,
         default: {},
      },
      video_info: {
         type: Object,
         default: {},
      },
   },
   mounted() {
      setTimeout(() => {
         this.player = videojs(this.$refs.videoPlayer, this.options, () => {
            this.player.log('onPlayerReady', this);
         });
      }, 500)
   },
   beforeDestroy() {
      if (this.player) {
         this.player.dispose();
      }
   },
   methods: {
      sorting() {
         this.video_info.video_kach.sort((a, b) => (a.kach > b.kach ? -1 : 1));
      },
      showMenuList() {
         this.menu = !this.menu
      },
      addInDefault() {
         var ui = this.video_info.video_kach.find(item => item.kach === 720)
         if (ui == undefined || ui == 'undefined') {
            this.video_info.video_kach.push({ link_video: this.video_info.link_video, kach: 720 })
         }
      },
      SetQuality(kach, link) {
         this.kach_current = kach
         var crTime = this.player.currentTime()
         this.player.src({ src: link, type: 'video/mp4' })
         this.player.currentTime(crTime)
         this.menu = false
      },
   },
};
</script>