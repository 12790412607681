<template>
    <div class="wrapper" :class="{ 'dark-theme': theme, 'light-theme': !theme }">
        <router-view />
        <Footer />
        <VueProgressBar />
        <Player
            :type-player="currentPlayer"
            :is-player-active="isPlayerActive"
        />
    </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, nextTick, ref, defineExpose, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Player from '@/components/Player/Player.vue';
import Footer from '@/components/Footer/Footer.vue';

const componentKey = ref(true);
const theme = ref('light');

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const instance = getCurrentInstance();
const $Progress = instance.appContext.config.globalProperties.$Progress;
const VueProgressBar = instance.appContext.app.component('vue-progress-bar');

const forceRerender = async () => {
    componentKey.value = false;

    await nextTick();

    componentKey.value = true;
}

$Progress.start();

router.beforeEach((to, from, next) => {
    if (to.meta.progress !== undefined) {
        $Progress.parseMeta(to.meta.progress);
    }

    // Ограничения для плееров, где какой должен играть
    if (to.path == '/radio') {
        if (store.state.currentPlayer.length) {
            store.commit('deactivatePlayer')
        }

        store.commit('activatePlayer', 'efir')
    }

    if (to.path == '/') {
        if (store.state.currentPlayer.length) {
            store.commit('deactivatePlayer')
        }
    }

    if (to.path == '/catalog/') {
        if (store.state.currentPlayer.length) {
            store.commit('deactivatePlayer')
        }
    }

    $Progress.start();

    next();
});

router.afterEach(() => {
    $Progress.finish();
});

onMounted(() => {
    $Progress.finish();

    document.title = t('title.singular');

    theme.value = Boolean(store.state.theme);
});

store.watch((state) => state.theme, (value) => {
    theme.value = Boolean(value);
});

const currentPlayer = computed(() => store.state.currentPlayer)
const isPlayerActive = computed(() => store.state.isPlayerActive)

defineExpose({
    forceRerender,
});

</script>

<style>
@import '@/assets/css/reset.css';
@import '@/assets/fonts/fonts.css';
@import '@/assets/css/slick.css';
@import '@/assets/css/style.css';
@import '@/assets/css/variable.css';
</style>