<template>
   <div class="player__video video_play">
      <p class="player__video_yers">{{ videoInfo.censored }}+</p>
      <video playsinline ref="videoPlayer" preload="metadata" @loadedmetadata="logDuration"
         class="video-js vjs-custom-skin">
      </video>
   </div>

   <div class="setting__quality" ref="list" v-if="videoInfo.video_kach">
      <span @click="showMenuList()"><img src="@/assets/img/settings.svg"></span>
      <ul class="menu_quality-video" v-if="menu" :class="{ 'one': videoInfo.video_kach.length == 1 }">
         <li v-for="list in videoInfo.video_kach"
            :class="{ 'active': kach_current == list.kach, 'one': videoInfo.video_kach.length == 1 }"
            @click="SetQuality(list.kach, list.link_video)">
            <a>{{ list.kach }}</a>
         </li>
      </ul>
   </div>
</template>
<style lang="scss">
@import '@/assets/css/MainComponent/main.css';

.marquee-wrapper {
   overflow: hidden;
   background-color: #0066C3
}

// .marquee-infinite {
//    display: -webkit-box;
//    display: -ms-flexbox;
//    display: flex;
//    gap: 30px;
//    -webkit-animation: marquee-infinite 10s linear infinite;
//    animation: marquee-infinite 10s linear infinite;
//    font-weight: 500;
//    font-size: 18px;
//    line-height: 100%;
//    height: 40px;
//    -webkit-box-align: center;
//    -ms-flex-align: center;
//    align-items: center;
//    color: #fff
// }


.runeline {
   height: 40px;
   color: white;
   font-size: 18px;
   font-weight: 500;
   line-height: 100%;
   background-color: #0066C3;
   overflow: hidden;
   display: flex;
   align-items: center;

   // margin: 0 auto;
   @media(max-width: 992px) {
      font-size: 14px;
      height: 32px;
   }
}

.marquee-0 {
   --space: 0rem;
   display: grid;
   align-content: center;
}

.marquee {
   --duration: v-bind(nduration);
   --per-duration: v-bind(ndurationPercent);
   --gap: var(--space);
   display: flex;
   user-select: none;
   position: relative;
   max-width: 100vw;
}

.marquee:hover {
   z-index: 99;
}

.marquee__group {
   flex-shrink: 0;
   display: flex;
   align-items: center;
   justify-content: space-around;
   min-width: 100%;
   animation: scroll var(--duration) linear infinite;
   // gap: 56px;
}

.marquee:hover .marquee__group {
   // animation-play-state: paused;
}

@media (prefers-reduced-motion: reduce) {
   .marquee__group {
      animation-play-state: paused;
   }
}

.marquee__group span {
   margin: 7px;
}


@keyframes scroll {
   0% {
      transform: translateX(var(--per-duration));
   }

   100% {
      transform: translateX(calc(-100% - var(--gap)));
   }
}

@media(max-width: 768px) {
   .marquee-infinite {
      height: 28px;
   }

   .marquee-track {
      font-size: 12px;
   }

   .video-live_stroke {
      //display: none !important;
      //bottom: 20px;
   }

   .video_play {
      .video-js {
         overflow: hidden;
      }
   }
}
</style>
<script>
import axios from 'axios'
import moment, { duration } from 'moment';
import videojs from 'video.js';


export default {
   name: 'MainComponent',
   data() {
      return {
         player: null,
         text: "",
         liveStroka: false,
         durationvideo: 0,

         errorIndex: 0,
         frequency: 4,
         currentLiveStroke: null,
         currentLiveStrokeId: 0,
         menu: false,
         kach_current: 720,
         nduration: '10s',
         ndurationPercent: "0%",
         videoContentLiveTickMap: new Map(),
         teleprogramLiveTickMap: new Set(),
      }
   },
   components: {

   },
   props: {
      options: {
         type: Object,
         default: {}
      },
      detail: {
         type: Object,
         default: {},
      },
      curr_time: {
         type: Number,
         default: 0,
      },
      durationInEfir: {
         type: Number,
         default: 0,
      },
      timeOfEfir: {
         type: String,
         default: "00:00:00",
      },
      timelive: {
         type: String,
         default: "00:00:00",
      },
      teleId: {
         type: Number,
         default: 0,
      },
      videoId: {
         type: Number,
         default: 0,
      },
      videoInfo: {
         type: Object,
         default: {},
      },
   },
   mounted() {
      setTimeout(() => {
         this.player = videojs(this.$refs.videoPlayer, this.options, () => {

            this.player.controlBar.progressControl.show();

            var ButtonComp = videojs.getComponent('Button');
            var notesBut = new ButtonComp(this.player, {
               clickHandler(event) {
                  this.player_.liveTracker.seekToLiveEdge();
               }
            });
            notesBut.addClass('addNotesBut')
            this.player.controlBar.addChild(notesBut, {}, 3)
            document.querySelector('.addNotesBut .vjs-icon-placeholder').innerHTML = `<p> ${this.$i18n.t('teleprogram.live.status.online.singular')} </p>`
            document.querySelector('.addNotesBut').style = 'width: auto !important'
         });
         var Component = videojs.getComponent('Component');

         var LiveStroke = videojs.extend(Component, {
            constructor(player, options) {
               Component.apply(this, arguments);

               if (options.text) {
                  this.createEl(options.text);
               }
            },
            createEl(text) {
               const el = videojs.createEl('div');
               const container = document.createElement('div');
               el.className = 'video-live_stroke';
               const runeline = document.createElement('div');
               runeline.className = 'runeline';

               const marquee0 = document.createElement('div');
               marquee0.className = 'marquee-0';

               const marquee = document.createElement('div');
               marquee.className = 'marquee marquee-b2b';
               const frequency = this.options_.frequency ?? 4;
               for(let i = 0; i < frequency; i += 2) {
                  const marqueeGroup = document.createElement('div');
                  marqueeGroup.className = 'marquee__group';
                  if (i != 0) {
                     marqueeGroup.setAttribute('aria-hidden', 'true');
                  }

                  const runningLineItemFirst = document.createElement('div');
                  runningLineItemFirst.className = 'running-line__item';
                  const spanFirst = document.createElement('span');
                  spanFirst.className = 'text14';
                  spanFirst.innerText = this.options_.text;
                  runningLineItemFirst.appendChild(spanFirst);
                  marqueeGroup.appendChild(runningLineItemFirst);
                  
                  if (i + 1 < frequency) {
                     const runningLineItemLast = document.createElement('div');
                     runningLineItemLast.className = 'running-line__item';
                     const span = document.createElement('span');
                     span.className = 'text14';
                     span.innerText = this.options_.text;
                     runningLineItemLast.appendChild(span);
                     marqueeGroup.appendChild(runningLineItemLast);
                  }
                  marquee.appendChild(marqueeGroup);
               }
               marquee0.appendChild(marquee);
               runeline.appendChild(marquee0);
               container.appendChild(runeline);
               el.innerHTML = container.innerHTML;

               return el;
            }
         });

         videojs.registerComponent('LiveStroke', LiveStroke);
      }, 500)
   },
   beforeDestroy() {
      if (this.player) {
         this.player.nuevo();
      }
   },
   computed: {
      isDesktopDevice() {
         if (window.innerWidth > 500) {
            return true
         } else {
            return false
         }
      }
   },
   methods: {
      addInDefault() {
         var ui = this.videoInfo.video_kach.find(item => item.kach === 720)
         if (ui == undefined || ui == 'undefined') {
            this.videoInfo.video_kach.push({ link_video: this.videoInfo.link_video, kach: 720 })
         }
      },
      sorting() {
         this.videoInfo.video_kach.sort((a, b) => (a.kach > b.kach ? -1 : 1));
      },
      showMenuList() {
         this.menu = !this.menu
      },
      SetQuality(kach, link) {
         this.kach_current = kach
         this.player.src({ src: link, type: 'video/mp4' })
         this.player.currentTime(this.curr_time)
         this.menu = false
      },
      logDuration() {
         this.$emit('duration', this.$refs.videoPlayer.duration)
         // this.player.currentTime(this.curr_time)
      },
      async LiveStroka() {
         await axios
            .get(`/api/v1/get/live-stroka/${this.teleId}/`)
            .then(response => {
               if (response.data.length >= 1) {
                  response.data.forEach((item) => {
                     if (item.video_content_id !== null) {
                        this.videoContentLiveTickMap.set(item.video_content_id, item);
                     } else {
                        this.teleprogramLiveTickMap.add(item);
                     }
                  });
               } else {
                  this.liveStroka = false
                  this.videoContentLiveTickMap.clear();
                  this.teleprogramLiveTickMap = [];
               }
            })
            .catch(error => {
               console.log(error)
            })

      },
   },
   watch: {
      curr_time() {
         const curr_time = this.curr_time;
         if (Number.isNaN(curr_time)) return;
         if (this.currentLiveStrokeId == this.videoId) return;
         
         if (this.currentLiveStroke && this.currentLiveStrokeId !== this.videoId) {
            this.player.removeChild(this.currentLiveStroke);
            this.currentLiveStroke = null;
            this.liveStroka = false;
            this.nduration = String('10s');
            this.text = "";
         }

         const currEfirTime = duration(this.timelive).asSeconds() - duration(this.timeOfEfir).asSeconds();
         const currentTele = Array.from(this.teleprogramLiveTickMap).find((item) => {
            const start_time = item.start_time;
            const end_time = start_time + item.duration;
            return currEfirTime >= start_time && currEfirTime <= end_time;
         });

         if (currentTele) {
            this.text = currentTele.message;
            this.currentLiveStroke = this.player.addChild('LiveStroke', { text: currentTele.message, frequency: currentTele.speed });
            this.currentLiveStrokeId = this.videoId;
            const dur = currentTele.start_time + currentTele.duration
            const d = Math.abs(currEfirTime - dur);
            this.ndurationPercent = String(`-${100 * ((dur - d) / dur)}%`);
            this.nduration = String(`${d}s`);
            this.teleprogramLiveTickMap.delete(currentTele);
            return;
         }

         const currentVideo = this.videoContentLiveTickMap.get(this.videoId);

         if (!currentVideo) return;

         const ending = currentVideo.duration;
         while (!this.player) {}

         
         if (curr_time >= currentVideo.start_time && curr_time <= currentVideo.start_time + ending) {
            this.text = currentVideo.message;
            this.currentLiveStroke = this.player.addChild('LiveStroke', { text: currentVideo.message, frequency: currentVideo.speed });
            this.currentLiveStrokeId = this.videoId;
            const d = Math.min(this.durationInEfir - this.curr_time, ending);
            const dur = currentVideo.start_time + ending;
            this.ndurationPercent = String(`-${100 * ((dur - d) / dur)}%`);
            this.nduration = String(`${d}s`);
            this.liveStroka = true;
            this.videoContentLiveTickMap.delete(this.videoId);
         }
      }
   },
   async created() {
      console.log('created');
      await this.LiveStroka()
   },
};
</script>