<template>
   <nav class="nav" :class="{ 'nav__active_search': search && !isMobile, 'nav__active': mobile__active }">
      <div class="container">
         <div class="nav__logo" @click="GoTo('/')">
            <svg xmlns="http://www.w3.org/2000/svg" width="210" height="73" viewBox="0 0 210 73" fill="none">
               <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M75.6027 4.56717L43.546 26.7246C36.3111 31.7252 36.1809 31.8629 35.9902 31.9095C35.3172 32.078 34.6437 32.2464 33.9707 32.4149C23.0659 39.8097 12.1611 47.2046 1.25637 54.5996C1.21478 54.6432 1.17319 54.6864 1.17191 54.7975C1.17057 54.9092 1.20924 55.0879 1.3337 55.2383C1.45825 55.3892 1.6686 55.5115 36.1633 55.5724C70.6582 55.6337 139.437 55.6337 173.876 55.5669C208.315 55.5004 208.414 55.3675 208.544 55.2319C208.675 55.096 208.837 54.958 208.78 54.6705C208.722 54.3834 208.444 53.9474 200.321 48.9895C192.198 44.0317 176.23 34.5524 160.261 25.0732C159.665 24.4192 159.069 23.7652 158.472 23.1113C146.664 15.7802 134.856 8.44971 128.76 4.76656C122.664 1.0842 122.279 1.05006 121.411 1.02125C120.543 0.992905 119.191 0.971085 118.293 1.10338C117.394 1.23635 116.948 1.52432 111.951 5.18897C106.953 8.85368 97.4046 15.8951 92.5852 19.435C87.7654 22.9748 87.6752 23.013 87.5624 22.9568C87.4495 22.901 87.3143 22.7513 87.2832 22.6503C87.2522 22.5498 87.3276 22.5006 89.3614 20.9932L95.3565 16.5493C97.393 15.0398 97.6322 14.8898 97.6517 14.7208C97.6712 14.5518 97.6846 14.5589 97.5454 14.3907C97.4065 14.2226 97.2595 14.0804 94.7291 12.2062C92.1988 10.332 87.324 6.70865 84.6644 4.89505C83.46 4.15835 82.7043 3.52133 82.0126 3.42327L78.0999 3.40242C77.3577 3.3983 76.781 3.75262 75.6027 4.56717Z"
                  fill="white" />
               <path
                  d="M75.6027 4.56717L43.546 26.7246C36.3111 31.7252 36.1809 31.8629 35.9902 31.9095C35.3172 32.078 34.6437 32.2464 33.9707 32.4149C23.0659 39.8097 12.1611 47.2046 1.25637 54.5996C1.21478 54.6432 1.17319 54.6864 1.17191 54.7975C1.17057 54.9092 1.20924 55.0879 1.3337 55.2383C1.45825 55.3892 1.6686 55.5115 36.1633 55.5724C70.6582 55.6337 139.437 55.6337 173.876 55.5669C208.315 55.5004 208.414 55.3675 208.544 55.2319C208.675 55.096 208.837 54.958 208.78 54.6705C208.722 54.3834 208.444 53.9474 200.321 48.9895C192.198 44.0317 176.23 34.5524 160.261 25.0732C159.665 24.4192 159.069 23.7652 158.472 23.1113C146.664 15.7802 134.856 8.44971 128.76 4.76656C122.664 1.0842 122.279 1.05006 121.411 1.02125C120.543 0.992905 119.191 0.971086 118.293 1.10338C117.394 1.23635 116.948 1.52432 111.951 5.18897C106.953 8.85368 97.4046 15.8951 92.5852 19.435C87.7654 22.9748 87.6752 23.013 87.5624 22.9568C87.4495 22.901 87.3143 22.7513 87.2832 22.6503C87.2522 22.5498 87.3276 22.5006 89.3614 20.9932L95.3565 16.5493C97.393 15.0398 97.6322 14.8898 97.6517 14.7208C97.6712 14.5518 97.6846 14.5589 97.5454 14.3907C97.4065 14.2226 97.2595 14.0804 94.7291 12.2062C92.1988 10.332 87.324 6.70865 84.6643 4.89505C83.46 4.15835 82.7043 3.52133 82.0126 3.42327L78.0999 3.40242C77.3577 3.3983 76.781 3.75262 75.6027 4.56717Z"
                  stroke="#00549F" stroke-width="0.439687" stroke-miterlimit="2.61313" />
               <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.9923 31.913L35.6382 32.0013L48.2176 30.4327L49.1574 33.8071L55.4935 30.4539L54.2879 45.7908L70.4098 37.0477L78.3324 44.4719L98.579 30.0406L104.197 39.1425L115.977 32.536L136.654 43.5829L137.889 29.2845L149.449 34.4757L146.29 30.1322L183.139 38.6726C189.623 42.5043 194.238 45.362 200.436 49.1078C206.634 52.8533 208.721 54.383 208.779 54.6704C208.836 54.9579 208.673 55.096 208.543 55.2316C208.412 55.3677 208.314 55.5006 173.863 55.5669C139.411 55.6337 70.6079 55.6337 36.1008 55.5723C1.59403 55.5114 1.38373 55.3893 1.25901 55.2382C1.1344 55.0879 1.09589 54.9091 1.10882 54.7758C1.16989 54.1319 28.4581 35.9539 33.9723 32.418L35.9923 31.913Z"
                  fill="#0066C3" />
               <path
                  d="M35.9923 31.913L35.6382 32.0013L48.2176 30.4327L49.1574 33.8071L55.4935 30.4539L54.2879 45.7908L70.4098 37.0477L78.3324 44.4719L98.579 30.0406L104.197 39.1425L115.977 32.536L136.654 43.5829L137.889 29.2845L149.449 34.4757L146.29 30.1322L183.139 38.6726C189.623 42.5043 194.238 45.362 200.436 49.1078C206.634 52.8533 208.721 54.383 208.779 54.6704C208.836 54.9579 208.673 55.096 208.543 55.2316C208.412 55.3677 208.314 55.5006 173.863 55.5669C139.411 55.6337 70.6079 55.6337 36.1008 55.5723C1.59403 55.5114 1.38373 55.3893 1.25901 55.2382C1.1344 55.0879 1.09589 54.9091 1.10882 54.7758C1.16989 54.1319 28.4581 35.9539 33.9723 32.418L35.9923 31.913Z"
                  stroke="#0066C3" stroke-width="0.439687" stroke-miterlimit="2.61313" />
               <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M86.2245 59.3083H97.8744V62.9414H86.2245V64.2434H95.859V67.8761H86.2245V69.3369H98.1496V72.9697H82.2593V72.9687H82.2373V59.3083H86.2245Z"
                  fill="#0066C3" />
               <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M104.514 69.3586H114.149V72.9917H100.549V72.9679H100.527V59.1497H104.514V69.3586Z" fill="#0066C3" />
               <path
                  d="M61.3438 63.2528L61.3444 63.2524H72.2444V59.252H60.4241C59.9739 59.252 59.5412 59.3323 59.1254 59.493C58.7095 59.6538 58.3449 59.8806 58.0312 60.1737C57.7177 60.4671 57.4653 60.8195 57.2745 61.2313C57.0836 61.6434 56.9883 62.1077 56.9883 62.6242V64.7397C56.9883 65.2563 57.0836 65.7207 57.2745 66.1323C57.4653 66.5443 57.7177 66.897 58.0312 67.19C58.3449 67.4833 58.7095 67.71 59.1254 67.8706C59.4703 68.0043 59.827 68.082 60.1956 68.1048H68.5043C68.744 68.1048 68.9404 68.3041 68.9404 68.5479C68.9404 68.7849 68.7551 68.9798 68.5247 68.9906L68.5226 68.9913H68.5043H57.2236V72.9917H69.4429C69.8928 72.9917 70.3258 72.9115 70.7415 72.7507C71.1574 72.5903 71.5221 72.3634 71.8356 72.0701C72.1493 71.7769 72.4014 71.4245 72.5924 71.0124C72.7831 70.6005 72.8786 70.1364 72.8786 69.6195V67.5044C72.8786 66.9875 72.7831 66.5232 72.5924 66.1115C72.4014 65.6994 72.1493 65.3472 71.8356 65.054C71.5221 64.7605 71.1574 64.5337 70.7415 64.3731C70.3973 64.2404 70.0412 64.1622 69.6736 64.1392H61.3453C61.1054 64.1392 60.9092 63.9396 60.9092 63.6961C60.9092 63.4527 61.1047 63.2533 61.3438 63.2528Z"
                  fill="#0066C3" />
               <path
                  d="M49.8154 73H55.1912L51.2646 69.1462C53.1944 69.1462 54.905 67.8649 54.905 65.7949V62.6324C54.905 60.6769 53.3561 59.2606 51.4691 59.2606H38.6055V73H42.5933V69.1462H45.7454L49.8154 73ZM50.2216 63.261C50.3192 63.2774 50.4108 63.309 50.4961 63.3554C50.8028 63.5108 51.015 63.8329 51.015 64.2033C51.015 64.7236 50.5958 65.1494 50.0837 65.1494H42.5933V65.1251V63.261V63.2569H50.0837C50.1125 63.2569 50.141 63.2582 50.1692 63.261H50.2216Z"
                  fill="#0066C3" />
               <path
                  d="M32.1977 72.9917H36.9835L29.633 60.4114C28.6628 58.7923 25.5833 58.831 24.6349 60.4539L17.3096 72.9917H22.095L23.3186 70.8803L30.9449 70.8864L32.1977 72.9917ZM27.1465 64.279L28.9051 67.3158H25.3672L27.1465 64.279Z"
                  fill="#0066C3" />
               <path
                  d="M136.171 72.1899C135.317 73.2577 132.731 73.2732 131.855 72.1899L127.091 65.5088V72.9963H126.389H123.082H123.046V60.5915C123.046 59.879 123.62 59.2964 124.321 59.2964H125.529C127.042 59.2964 127.573 59.273 128.59 60.6908L134.003 68.2315L139.449 60.6707C140.449 59.281 140.982 59.2964 142.468 59.2964H143.676C144.378 59.2964 144.951 59.879 144.951 60.5915V72.9963H141.608V72.992H140.936V65.5088L136.171 72.1899Z"
                  fill="#0066C3" />
               <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M147.242 59.3083H151.227H162.877V62.9414H151.227V64.2434H160.862V67.8761H151.227V69.3369H163.152V72.9697H147.262V72.9687H147.24V59.3083H147.242Z"
                  fill="#0066C3" />
               <path
                  d="M165.066 72.9697H178.427C178.875 72.9697 179.305 72.8903 179.719 72.7303C180.132 72.5707 180.495 72.3451 180.807 72.0533C181.119 71.7615 181.37 71.4111 181.56 71.0012C181.749 70.5918 181.844 70.1299 181.844 69.6161V62.6587C181.844 62.1448 181.749 61.6834 181.56 61.2737C181.37 60.8637 181.119 60.5137 180.807 60.2218C180.495 59.93 180.132 59.7043 179.719 59.5446C179.305 59.3852 178.875 59.3052 178.427 59.3052H165.066V72.9697ZM177.187 69.3269H169.053V62.9222H177.187H177.252C177.595 62.9222 177.876 63.2077 177.876 63.5565V68.7138C177.876 69.0509 177.605 69.3269 177.273 69.3269H177.187Z"
                  fill="#0066C3" />
               <path fill-rule="evenodd" clip-rule="evenodd" d="M183.781 72.9697V59.3093H187.768V72.9697H183.781Z"
                  fill="#0066C3" />
               <path
                  d="M204.214 72.9917H209L201.65 60.4114C200.679 58.7923 197.6 58.831 196.652 60.4539L189.326 72.9917H194.112L195.335 70.8803L202.962 70.8864L204.214 72.9917ZM199.163 64.279L200.922 67.3158H197.384L199.163 64.279Z"
                  fill="#0066C3" />
               <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.0061 59.0755H13.5023C15.1161 59.0755 16.4362 60.4168 16.4362 62.0565V63.4144C16.4362 64.1304 16.1743 64.7877 15.7433 65.2912C16.5358 65.8606 17.0559 66.7984 17.0559 67.8539V69.5459C17.0559 71.3271 15.6216 72.7842 13.8686 72.7842H1V64.7179H1.0061V59.0755ZM4.5577 68.0218H12.7053C12.9577 68.0218 13.1645 68.232 13.1645 68.4884C13.1645 68.7453 12.9576 68.955 12.7053 68.955H4.5577V68.0218ZM4.54503 63.1583H12.3586C12.6113 63.1583 12.8178 63.3684 12.8178 63.6249C12.8178 63.8819 12.6113 64.0916 12.3586 64.0916H4.54503V63.1583Z"
                  fill="#0066C3" />
            </svg>
         </div>
         <div class="nav-new__emblem nav-new__emblem_mobile" v-if="!search || isMobile">
            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
               <path
                  d="M29.084 23.3935C26.195 23.3935 23.8515 25.8245 23.8515 28.8215C23.8515 31.8187 26.195 34.2495 29.084 34.2495C31.974 34.2495 34.3171 31.8187 34.3171 28.8215C34.3171 25.8245 31.974 23.3935 29.084 23.3935ZM14.2287 13.9221C17.3254 22.7283 18.1535 32.9646 14.3492 44.1991C20.6537 41.2935 26.7863 38.2553 44.1334 43.8347C39.0703 34.8115 40.67 24.4456 43.6506 13.8009C34.0332 18.5559 24.1662 17.1072 14.2287 13.9221ZM12.0583 8.57237C22.5463 13.7982 33.2192 15.8525 46.062 9.05853C34.6173 0.11269 22.9484 1.4441 12.0583 8.57237ZM48.8357 11.4906C42.847 22.3524 42.8062 33.7011 48.7152 45.5366C57.2965 33.8432 56.3328 22.5352 48.8357 11.4906ZM12.7817 48.5765C22.9134 56.33 33.8032 57.1286 45.7009 48.6981C34.055 42.2191 23.1424 42.7548 12.7817 48.5765ZM9.28448 11.9766C1.82832 23.5384 0.903662 34.7043 9.5259 45.2935C15.3938 34.3496 15.3141 23.2443 9.28448 11.9766ZM28.9998 0C45.0133 0 58 12.9864 58 29.0001C58 45.0138 45.0133 58 28.9998 58C12.9865 58 0 45.0138 0 29.0001C0 12.9864 12.9865 0 28.9998 0Z"
                  fill="#019040" />
            </svg>
         </div>
         <ul>
            <li class="nav__ul_search" :class="{ 'active': search }" v-if="isMobile">
               <Search :is-mobile="true" />
            </li>
            <li><a :class="{ active: route.path == '/' }" @click="GoTo('/')" class="nav__video">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M4.36077 1.27343C3.36667 0.57687 2 1.28802 2 2.50188V9.49827C2 10.7121 3.36668 11.4233 4.36078 10.7267L9.36486 7.22028C10.2115 6.62704 10.2115 5.373 9.36484 4.77977L4.36077 1.27343Z"
                        fill="#262F44" />
                  </svg>
                  <p>
                     <i18n-t keypath="teleprogram.live.title.singular" scope="global"></i18n-t>
                  </p>
               </a></li>
            <li><a :class="{ active: route.path == '/radio' }" @click="GoTo('/radio')" class="nav__video">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M9.73909 3.23028C9.6737 2.70719 9.05575 2.46334 8.65078 2.80082L5.30471 5.58921C5.24481 5.63913 5.16929 5.66647 5.09132 5.66647H2.49935C2.03911 5.66647 1.66602 6.03957 1.66602 6.4998V9.4998C1.66602 9.96004 2.03911 10.3331 2.49935 10.3331H5.09132C5.16929 10.3331 5.24481 10.3605 5.30471 10.4104L8.65078 13.1988C9.05575 13.5363 9.6737 13.2924 9.73909 12.7693L9.80296 12.2583C10.1565 9.43032 10.1565 6.56929 9.80296 3.74128L9.73909 3.23028Z"
                        fill="#82919F" />
                     <path
                        d="M13.2715 3.96365C13.5336 3.87694 13.8165 4.01918 13.9032 4.28136C14.2901 5.45134 14.4994 6.70164 14.4994 7.99981C14.4994 9.29798 14.2901 10.5483 13.9032 11.7183C13.8165 11.9804 13.5336 12.1227 13.2715 12.036C13.0093 11.9493 12.867 11.6664 12.9537 11.4043C13.3076 10.3343 13.4994 9.18995 13.4994 7.99981C13.4994 6.80967 13.3076 5.66532 12.9537 4.59537C12.867 4.33319 13.0093 4.05036 13.2715 3.96365Z"
                        fill="#82919F" />
                     <path
                        d="M12.0209 5.29827C11.9256 5.03907 11.6383 4.90615 11.3791 5.00139C11.1199 5.09662 10.987 5.38395 11.0822 5.64315C11.3519 6.3771 11.4993 7.17068 11.4993 7.99981C11.4993 8.68755 11.3979 9.3507 11.2095 9.97558C11.199 10.0104 11.1882 10.0451 11.1772 10.0797C11.1475 10.1728 11.1158 10.2651 11.0822 10.3565C10.987 10.6157 11.1199 10.903 11.3791 10.9982C11.6383 11.0935 11.9256 10.9605 12.0209 10.7013C12.0594 10.5965 12.0957 10.4906 12.1299 10.3837C12.1425 10.344 12.1549 10.3042 12.1669 10.2642C12.3832 9.54676 12.4993 8.78639 12.4993 7.99981C12.4993 7.05128 12.3305 6.14105 12.0209 5.29827Z"
                        fill="#82919F" />
                  </svg>
                  <p>
                     <i18n-t keypath="radioprogram.live.title.singular" scope="global"></i18n-t>
                  </p>
               </a></li>
            <li class="nav-new__li">
               <a :class="{ active: $route.path == '/tv' }" @click="GoTo('/tv')">
                  <p>
                     <i18n-t keypath="teleprogram.title.singular" scope="global"></i18n-t>
                  </p>
               </a>
               <div class="nav-new__emblem">
                  <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
                     <path
                        d="M29.084 23.3935C26.195 23.3935 23.8515 25.8245 23.8515 28.8215C23.8515 31.8187 26.195 34.2495 29.084 34.2495C31.974 34.2495 34.3171 31.8187 34.3171 28.8215C34.3171 25.8245 31.974 23.3935 29.084 23.3935ZM14.2287 13.9221C17.3254 22.7283 18.1535 32.9646 14.3492 44.1991C20.6537 41.2935 26.7863 38.2553 44.1334 43.8347C39.0703 34.8115 40.67 24.4456 43.6506 13.8009C34.0332 18.5559 24.1662 17.1072 14.2287 13.9221ZM12.0583 8.57237C22.5463 13.7982 33.2192 15.8525 46.062 9.05853C34.6173 0.11269 22.9484 1.4441 12.0583 8.57237ZM48.8357 11.4906C42.847 22.3524 42.8062 33.7011 48.7152 45.5366C57.2965 33.8432 56.3328 22.5352 48.8357 11.4906ZM12.7817 48.5765C22.9134 56.33 33.8032 57.1286 45.7009 48.6981C34.055 42.2191 23.1424 42.7548 12.7817 48.5765ZM9.28448 11.9766C1.82832 23.5384 0.903662 34.7043 9.5259 45.2935C15.3938 34.3496 15.3141 23.2443 9.28448 11.9766ZM28.9998 0C45.0133 0 58 12.9864 58 29.0001C58 45.0138 45.0133 58 28.9998 58C12.9865 58 0 45.0138 0 29.0001C0 12.9864 12.9865 0 28.9998 0Z"
                        fill="#019040" />
                  </svg>
               </div>
            </li>
            <li><a :class="{ active: route.path == '/radio/program' }" @click="GoTo('/radio/program')">
                  <p>
                     <i18n-t keypath="radioprogram.title.singular" scope="global"></i18n-t>
                  </p>
               </a></li>
            <li><a :class="{ active: route.path == '/catalog' || route.path == '/radio/catalog' }"
                  @click="GoTo('/catalog')">
                  <p>
                     <i18n-t keypath="catalog.title.singular" scope="global"></i18n-t>
                  </p>
               </a></li>
            <li>
               <a :class="{ active: route.path == '/about' }" @click="GoTo('/about')">
                  <p>
                     <i18n-t keypath="about.title.singular" scope="global"></i18n-t>
                  </p>
               </a>
            </li>
         </ul>
         <div :class="{'nav__search_container_wrapper': search}" v-if="!isMobile">
            <Search :is-mobile="isMobile" />
         </div>
         <div class="nav__theme">
            <span class="wht" :class="{ 'active': !theme }" @click="ThemeLight()">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V3C12.75 3.41421 12.4142 3.75 12 3.75C11.5858 3.75 11.25 3.41421 11.25 3V2C11.25 1.58579 11.5858 1.25 12 1.25Z"
                     fill="#262F44" />
                  <path
                     d="M12 6.25C8.82436 6.25 6.25 8.82436 6.25 12C6.25 15.1756 8.82436 17.75 12 17.75C15.1756 17.75 17.75 15.1756 17.75 12C17.75 8.82436 15.1756 6.25 12 6.25Z"
                     fill="#262F44" />
                  <path
                     d="M5.45928 4.39862C5.16638 4.10573 4.69151 4.10573 4.39862 4.39862C4.10572 4.69152 4.10572 5.16639 4.39862 5.45929L5.10572 6.16639C5.39862 6.45929 5.87349 6.45929 6.16638 6.16639C6.45928 5.8735 6.45928 5.39862 6.16638 5.10573L5.45928 4.39862Z"
                     fill="#262F44" />
                  <path
                     d="M22.75 12C22.75 12.4142 22.4142 12.75 22 12.75H21C20.5858 12.75 20.25 12.4142 20.25 12C20.25 11.5858 20.5858 11.25 21 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12Z"
                     fill="#262F44" />
                  <path
                     d="M19.6014 5.45928C19.8943 5.16638 19.8943 4.69151 19.6014 4.39862C19.3085 4.10572 18.8336 4.10572 18.5407 4.39862L17.8336 5.10572C17.5407 5.39862 17.5407 5.87349 17.8336 6.16638C18.1265 6.45928 18.6014 6.45928 18.8943 6.16638L19.6014 5.45928Z"
                     fill="#262F44" />
                  <path
                     d="M12 20.25C12.4142 20.25 12.75 20.5858 12.75 21V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V21C11.25 20.5858 11.5858 20.25 12 20.25Z"
                     fill="#262F44" />
                  <path
                     d="M18.8943 17.8336C18.6014 17.5407 18.1266 17.5407 17.8337 17.8336C17.5408 18.1265 17.5408 18.6014 17.8337 18.8943L18.5408 19.6014C18.8337 19.8943 19.3085 19.8943 19.6014 19.6014C19.8943 19.3085 19.8943 18.8336 19.6014 18.5407L18.8943 17.8336Z"
                     fill="#262F44" />
                  <path
                     d="M3.75 12C3.75 12.4142 3.41421 12.75 3 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H3C3.41421 11.25 3.75 11.5858 3.75 12Z"
                     fill="#262F44" />
                  <path
                     d="M6.16632 18.8943C6.45921 18.6014 6.45921 18.1265 6.16632 17.8336C5.87342 17.5407 5.39855 17.5407 5.10566 17.8336L4.39855 18.5407C4.10566 18.8336 4.10566 19.3085 4.39855 19.6014C4.69144 19.8943 5.16632 19.8943 5.45921 19.6014L6.16632 18.8943Z"
                     fill="#262F44" />
               </svg>
            </span>
            <span class="blue" :class="{ 'active': theme }" @click="ThemeDark()">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.4176 20.5 20.0476 17.1303 20.4608 12.8207C20.4801 12.6202 20.377 12.4277 20.1995 12.3324C20.0219 12.2372 19.8045 12.2578 19.6481 12.3848C18.7884 13.0824 17.6937 13.5 16.5 13.5C13.7386 13.5 11.5 11.2614 11.5 8.5C11.5 6.8599 12.2892 5.40423 13.5106 4.49167C13.6721 4.37101 13.7453 4.16516 13.6963 3.96961C13.6473 3.77406 13.4857 3.62706 13.2864 3.59678C12.8666 3.53302 12.437 3.5 12 3.5Z"
                     fill="#ABB8C4" />
               </svg>
            </span>
         </div>
         <div class="nav__actions">
            <LanguageSwitch :is-mobile="isMobile" />
            <div class="nav__humburger" @click="Menu()">
               <span></span>
            </div>
         </div>
      </div>
   </nav>
</template>

<style scoped lang="scss">
.nav ul a.active,
.nav ul a:hover {
   @media(min-width: 1024px) {
      &::before {
         bottom: -35px;
      }
   }
}

.nav__search_container_wrapper {
   flex-grow: 1;
   width: 100%;
}

.nav__actions {
   display: flex;
   align-items: center;

   @media(max-width: 650px) {
      position: absolute;
      top: 0.25rem;
      right: 1rem;
   }
}

.nav__result_item {
   height: 260.48px;
}

@media (max-width: 1024px) {
   .nav__result_item {
      height: 153.92px;
   }
}

.nav {
   min-height: 100px;
   padding-top: 19px;

   &__logo {
      max-width: 208px;
      margin-right: 100px;
      width: unset;
      cursor: pointer;

      svg {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   ul {
      margin-left: 0;

      li {
         white-space: nowrap;
      }
   }
}

.nav-new {
   &__logo {
      margin-left: 64px;
      max-width: 184px;

      svg {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__li {
      display: flex;
      align-items: center;
      gap: 24px;
   }

   &__emblem {
      &_mobile {
         display: none;

      }
   }
}

@media(max-width: 1700px) {
   .nav {
      &__logo {
         margin-right: 32px;
      }
   }

   .nav-new {
      &__logo {
         margin-left: 32px;
      }
   }
}

@media(max-width: 1024px) {
   .nav {
      padding: 8px 0;
      min-height: 72px;

      &__logo {
         width: 140px;
      }
   }

   .nav-new {
      &__li {
         .nav-new__emblem {
            display: none;
         }
      }

      &__logo {
         width: 140px;
      }

      &__emblem_mobile {
         display: block;
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         top: 16px;
         width: 40px;
         height: 40px;

         svg {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
   }
   .nav__ul_search {
      grid-column: 1 / span 6;
      &.active {
         width: 100%;
         
         & .nav__search_container {
            flex-grow: 1;
            &>*:last-child {
               display: flex;
               height: 48px;
               align-items: center;
               justify-content: space-between;
               order: -1;
               flex-grow: 0.5;
            }
         }
      }
   }

   ul li {
      grid-column: 1;
   }
}

@media(max-width: 768px) {
   .nav-new {
      &__logo {
         display: none;
      }
   }

}

@media(max-width: 650px) {
   .nav {
      &__search {
         margin-right: 10px;
      }
   }
}

@media(max-width: 374px) {
   .nav-new__emblem {
      display: none;
   }
}
</style>

<script setup>
import axios from 'axios';
import { onMounted, ref, provide } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { tryCatch } from '@/utils/handle';
import LanguageSwitch from './LanguageSwitch.vue';
import Search from './Search.vue';
import { useScreenSize } from '@/utils/screen';

const store = useStore();
const router = useRouter();
const route = useRoute();

const search = ref(false);
const changeSearch = (value) => search.value = value;

provide('search', search);
provide('changeSearch', changeSearch);

const props = defineProps({
   fiol: {
      type: Boolean,
      default: false
   },
   user__profile: {
      type: Boolean,
      default: false
   },
});

const videos = ref([]);
const theme = ref(false);
const mobile__active = ref(false);
const [isMobile] = useScreenSize();

const GetRecommendedVideo = async () => {
   await tryCatch(null, async () => {
      const response = await axios.get('/api/v1/get/search/recomended/');

      videos.value = response.data;
   });
};

onMounted(() => {
   store.commit('initializeStore');

   theme.value = Boolean(store.state.theme);

   GetRecommendedVideo();
});

const GoTo = (path) => router.push(path);
const Menu = () => mobile__active.value = !mobile__active.value;

provide('Menu', Menu);

const ThemeLight = () => {
   store.commit('initializeStore');

   theme.value = false;

   localStorage.removeItem('theme');

   store.commit('removeTheme');
};

const ThemeDark = () => {
   store.commit('initializeStore');

   theme.value = true;

   store.commit('setTheme', Boolean(theme.value));
   localStorage.setItem('theme', Boolean(theme.value));
};
</script>